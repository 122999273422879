import React from 'react'
import { useParams } from 'react-router-dom';
import { trabajos } from '../../../data/trabajos';
import { Link } from 'react-router-dom';

import './css/detalle.css'

export const Detalle = () => {
    const { id } = useParams();
    const trabajo = trabajos.find(trab => trab.id === id);

    console.log(id);

  return (
    <>
    <div className='contentPortafolio'>
        <section className='work_detalle'>
            <article key={trabajo.id} className='articuloTrabajoDetalle'>
                <div className='mask'>
                    <img src={"/img/"+trabajo.id+".jpg"} alt={trabajo.nombre} id={trabajo.id === 'conversor' ? 'imgConversor' : null} />
                </div>

                <div className='infoWorks'>
                    <h1>{trabajo.nombre}</h1>
                    <p><strong>URL:</strong> {trabajo.url}</p>
                    <p><strong>Tecnologías:</strong> {trabajo.tecnologias}</p>
                    <div id='descripcion'>
                        <p><strong>Descripción:</strong></p>
                        {trabajo.descripcion.split('\n').map((parrafo, index) => (
                            <p key={index}>{parrafo}</p>
                        ))}
                    </div>

                    <div className='divBtnAcceder'>
                        <Link to={`${trabajo.url}`} id='Acceder'>Acceder</Link>
                    </div>

                </div>

            </article>
        </section>
    </div>
    
    </>
  )
}
