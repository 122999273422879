export const presskits = [
    {
        'id' : 'marc',
        'nombre' : 'Presskit Marc Arrayo',
        'software' : 'Illustrator',
        'url' : 'https://drive.google.com/file/d/1RaexQFy_L1HpcKdtTs4SJO21Uv67qElS/view?usp=drive_link',
        'descripcion' : `Marc Arroyo es un dj con una dilatada experiencia en el vibrante panorama musical, pues más de una década ininterrumpida en el sector le avalan.
        Desde muy pequeño sintió la pasión por este mundo, la cual ha llevado a cada uno de los shows de este artista que sin duda deja huella en cada uno de ellos demostrando su magia y su vibrante carácter mezclando en cabina.
        La versatilidad y su capacidad para adaptarse a cualquier entorno y público hacen de él alguien imprescindible para cualquier evento u ocasión.
        `
    },
    {
        'id' : 'chack',
        'nombre' : 'Presskit Chack Crespo',
        'software' : 'Illustrator',
        'url' : 'https://drive.google.com/file/d/1yhhH-fDkvZczwRLhDOPp0DGSuGUsXSUi/view?usp=sharing',
        'descripcion' : ``
    },
    {
        'id' : 'abri',
        'nombre' : 'Presskit Abri',
        'software' : 'Illustrator',
        'url' : 'https://drive.google.com/file/d/1UVRIj2QVtN5ZMiGnz-nz7evQRCSCtA-D/view?usp=sharing',
        'descripcion' : ``
    },
    {
        'id' : 'jennifer',
        'nombre' : 'Presskit Jennifer',
        'software' : 'PhotoShop',
        'url' : 'https://drive.google.com/file/d/195-NPPEeLQYrWJrDQyApobKlfEw-sgwG/view?usp=drive_link',
        'descripcion' : ``
    },
]

export const entradas = [
    {
        'id' : 'cabobercianoentrada',
        'nombre' : 'Entrada Indepencia Cabo Verde',
        'software' : 'PhotoShop',
        'descripcion' : ``
    },
]