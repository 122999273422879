import React from 'react'

import "./css/inicio.css";


export const Presentacion2 = () => {
  return (
    <>
    <div className='presentacion_video'>

      <div className='contenedorPresentacion_video'>
        <video controls autoPlay muted loop className='videoPresentacion'>
            <source src={`${process.env.PUBLIC_URL}/videos/video_presentacion_web_2.mp4`} type='video/mp4'/>
        </video>
      </div>

    </div>
  </>
  )
}
