import React from 'react'
import { videos } from "./../data/videos";

import "./css/film.css";
import { Presentacion2 } from './subComponentes/inicio/Presentacion2';

export const Film = () => {
  return (
    <>
        <Presentacion2 />

        <div className='contentFilms'>
            <h1>Videos Premiere</h1>
            <section className='works_films'>
                {videos.map(video=>(
                    <article key={video.id} className='articleVideos'>
                        <h3>{video.nombre}</h3>
                        <video controls>
                            <source src={`${process.env.PUBLIC_URL}/videos/${video.id}.mp4`} type='video/mp4'/>
                        </video>
                    </article>
                ))}
            </section>
        </div>
    </>
  )
}
