export const videos = [
    {
        'id' : 'editando_semanasanta_valhalla',
        'nombre' : 'Proceso de edición Valhalla',
        'software' : 'Premiere Pro y After Effects',
        'url' : '',
        'descripcion' : ``
    },
    {
        'id' : 'ines_fit',
        'nombre' : 'Ines entrenamiento de Espalda',
        'software' : 'Premiere Pro y After Effects',
        'url' : '',
        'descripcion' : ``
    },
    {
        'id' : 'trindade_1',
        'nombre' : 'Entrenamiento Trindade',
        'software' : 'Premiere Pro',
        'url' : '',
        'descripcion' : ``
    },
    {
        'id' : 'MA_Fanatico',
        'nombre' : 'Marc Arroyo en el Fanático',
        'software' : 'Premiere Pro y After Effects',
        'url' : '',
        'descripcion' : ``
    },
    {
        'id' : 'valhalla_sanjuanbosco',
        'nombre' : 'San Juan Bosco en el Valhalla',
        'software' : 'Premiere Pro y After Effects',
        'url' : '',
        'descripcion' : ``
    },
    {
        'id' : 'chonin',
        'nombre' : 'Ford Focus en la montaña',
        'software' : 'Premiere Pro y After Effects',
        'url' : '',
        'descripcion' : ``
    },
    {
        'id' : 'abri_fanatico',
        'nombre' : 'AbriLink en el Fanático',
        'software' : 'Premiere Pro y After Effects',
        'url' : '',
        'descripcion' : ``
    },
    {
        'id' : 'valhalla_halloween',
        'nombre' : 'Halloween en el Valhalla',
        'software' : 'Premiere Pro y After Effects',
        'url' : '',
        'descripcion' : ``
    },
    {
        'id' : 'cc_boda',
        'nombre' : 'Chack Crespo boda',
        'software' : 'Premiere Pro y After Effects',
        'url' : '',
        'descripcion' : ``
    },
    {
        'id' : 'valhalla_carnaval',
        'nombre' : 'Carnaval en el Valhalla',
        'software' : 'Premiere Pro y After Effects',
        'url' : '',
        'descripcion' : ``
    },
    

]