import React from 'react';

import "../index.css";
import "./css/inicio.css";

import { Presentacion } from './subComponentes/inicio/Presentacion';
import { Bienvenido } from './subComponentes/inicio/Bienvenido';
import {Typewriter} from './helpers/Typewrite.js';
import SplashScreen from './helpers/SplashScrenn.js';
import { Presentacion2 } from './subComponentes/inicio/Presentacion2.js';

export const Inicio = () => {

  const fila1props = {
    titulo: 'Formación',
    texto: [
      '¡Bienvenido a mi página web! Soy Nelson de Matos, un apasionado del desarrollo y diseño web, con sólidas habilidades técnicas y experiencia en la creación de experiencias digitales excepcionales',
      'Utilizo lenguajes como JavaScript y PHP para desarrollar sitios web innovadores y funcionales. Mi pasión por JavaScript me ha llevado a especializarme en React JS, Node JS, Express JS y MongoDb, formando parte del stack MERN.',
      'Además tengo experiencia en principios fundamentales de sistemas informáticos, gestión de bases de datos SQL y control de versiones con GIT.',
      'Complementando mis habilidades de desarrollo web, también soy competente en el uso de herramientas de diseño como Illustrator y Photoshop, y en la edición de video con Premiere Pro. Estos trabajos se pueden ver en las secciones de Visuals y Films respectivamente.' 
    ],
    claseImagen: 'columna2Bienvenido',
  };

  const fila2props = {
    titulo: 'Experiencia',
    texto: [
      'Durante mi formación en Desarrollo de Aplicaciones Web tuve la oportunidad de realizar prácticas en la Academia del Transportista, donde brindé apoyo integral al equipo de desarrollo web y al equipo de soporte técnico. Mis responsabilidades incluyeron colaborar en el diseño, desarrollo y mantenimiento de aplicaciones web, así como proporcionar asistencia técnica para resolver problemas y mejorar la experiencia de usuario de los productos digitales de la compañia',
      'Esta experiencia me permitió aplicar mis conocimientos teóricos en un entorno profesional y desarrollar habilidades prácticas en el campo del desarrollo web. Colaborando en la actualización de su página web, la cual se está elaborando con las tecnologías que forman el stack MERN.'
    ],
    claseImagen: 'columna2Versatilidad',
  }

  return (
    
    <>
      <SplashScreen />
      
      <Presentacion />
      <Bienvenido {...fila1props}/>

      <Bienvenido {...fila2props}/>

    </>
  )
}
