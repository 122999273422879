import React from 'react';
import { Link } from 'react-router-dom';
import "./css/visuales.css";
import {presskits, carteles, entradas} from './../data/visuales';



const Presskits = () => {
  return (
    <>
        <div className='contentPresskit'>
            <h1>Proyectos Illustrator y Photoshop</h1>

            <section className='works'>

                {presskits.map(presskit=>(

                    <article key={presskit.id}>

                            <div className='mask'>
                                <img src={`${process.env.PUBLIC_URL}/img/${presskit.id}.JPG`}  alt={presskit.nombre} id={presskit.id} />
                            </div>
                                
                            <div className='infoWorks'>
                                <h3>{presskit.nombre}</h3>
                                {/*<p><strong>URL:</strong> {trabajo.url}</p>*/}
                                <p><strong>Software: </strong> {presskit.software}</p>

                                <div className='divBtnAcceder'>
                                    <Link to={presskit.url} id='btnVerMas' target='blanck'>Ver más</Link>
                                </div>
                            </div>
                    </article>

                ))}
            </section>
        </div>

        <div className='contentEntradas'>
            <h1>Proyectos entradas</h1>

            <section className='works_entradas'>

                {entradas.map(entrada=>(

                    <article key={entrada.id}>

                            <div className='mask'>
                                <img src={`${process.env.PUBLIC_URL}/img/${entrada.id}.JPG`}  alt={entrada.nombre} id={entrada.id} />
                            </div>
                                
                            <div className='infoWorks'>
                                <h3>{entrada.nombre}</h3>
                                {/*<p><strong>URL:</strong> {trabajo.url}</p>*/}
                                <p><strong>Software: </strong> {entrada.software}</p>
                                    
                            </div>
                    </article>

                ))}
            </section>
        </div>
    </>
  )
}

export default Presskits